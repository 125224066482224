<template>
  <div class="home">
    <div class="nav ds_flex al_center">
      <div class="item ds_flex al_center" @click="chooseWeek" :class="[dateType === 'week' ? 'active' : '']">{{week}}</div>
      <div class="item ds_flex al_center" @click="chooseMonth" :class="[dateType === 'month' ? 'active' : '']">{{month}}</div>
    </div>
    <div class="title">药康夫</div>
    <div class="card_box ds_flex al_center">
      <div class="item" @click="toYkfDetail(1)">
        <p class="t1">开通药企</p>
        <p class="n">{{ykfData.enterpriseAddCount}}</p>
        <p class="t2 icon" :class="{icon_up: ykfData.enterpriseRatio && ykfData.enterpriseRatio.indexOf('-') == -1, icon_down: ykfData.enterpriseRatio && ykfData.enterpriseRatio.indexOf('-') > -1 && ykfData.enterpriseRatio.indexOf('--') == -1}">环比{{ykfData.enterpriseRatio}}</p>
      </div>
      <div class="item" @click="toYkfDetail(2)">
        <p class="t1">开通药店</p>
        <p class="n">{{ykfData.storeAddCount}}</p>
        <p class="t2 icon" :class="{icon_up: ykfData.storeRatio && ykfData.storeRatio.indexOf('-') == -1, icon_down: ykfData.storeRatio && ykfData.storeRatio.indexOf('-') > -1 && ykfData.storeRatio.indexOf('--') == -1}">环比{{ykfData.storeRatio}}</p>
      </div>
    </div>
    <div class="title">O2O商城</div>
    <div class="card_box ds_flex al_center">
      <div class="item" @click="otoData">
        <p class="t1">开通药企</p>
        <p class="n">0</p>
        <p class="t2 icon">环比--</p>
      </div>
      <div class="item" @click="otoData">
        <p class="t1">开通药店</p>
        <p class="n">0</p>
        <p class="t2 icon">环比--</p>
      </div>
    </div>
    <div class="title">电子处方</div>
    <div class="card_box ds_flex al_center">
      <div class="item" @click="toCfDetail(1)">
        <p class="t1">开通药企</p>
        <p class="n">{{prescriptionData.monthEnterpriseCount}}</p>
        <p class="t2 icon" :class="{icon_up: prescriptionData.monthEnterpriseCountPrecent&& prescriptionData.monthEnterpriseCountPrecent.indexOf('-') == -1, icon_down: prescriptionData.monthEnterpriseCountPrecent && prescriptionData.monthEnterpriseCountPrecent.indexOf('-') > -1 && prescriptionData.monthEnterpriseCountPrecent.indexOf('--') == -1}">环比{{prescriptionData.monthEnterpriseCountPrecent}}</p>
      </div>
      <div class="item" @click="toCfDetail(2)">
        <p class="t1">开通药店</p>
        <p class="n">{{prescriptionData.monthStoreCount}}</p>
        <p class="t2 icon" :class="{icon_up: prescriptionData.monthStoreCountPrecent && prescriptionData.monthStoreCountPrecent.indexOf('-') == -1, icon_down: prescriptionData.monthStoreCountPrecent && prescriptionData.monthStoreCountPrecent.indexOf('-') > -1 && prescriptionData.monthStoreCountPrecent.indexOf('--') == -1}">环比{{prescriptionData.monthStoreCountPrecent}}</p>
      </div>
    </div>
    <!-- 选择周 -->
    <van-popup v-model="weelyShow" position="bottom" custom-style="height: 20%;" @close="weelyClose">
      <change-week @onConfirm="onConfirmWeek" @cancel="onCancelWeek" :defaults="new Date()" />
    </van-popup>
    <!-- 选择月 -->
    <van-popup v-model="monthShow" position="bottom" custom-style="height: 20%;" @close="weelyClose">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" @confirm="onConfirmMonth" @cancel="onCancelMonth" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
  </div>
</template>
<script>
/**
 * 统计数据接口统一访问药易加系统，原api文档上的url统一添加以下前缀访问：
/prescription/*   电子处方接口转发前缀
/yaokangfu/*   药康夫接口转发前缀
 */
let self = null;
import { getDate, getMonthStartAndEnd, getWeekStartAndEnd } from '@/utils/util';
import { Notify, Toast } from 'vant';
import changeWeek from '@/components/changeWeek';
export default {
  components: {
    changeWeek
  },
  data() {
    return {
      ykfData: {},
      prescriptionData: '',
      weelyShow: false,
      dateType: 'week',
      weekDate: '',
      type: 1,
      week: '本周',
      month: '本月',
      monthShow: false, //月显示
      minDate: new Date(2019, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      userInfo: JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created() {
    self = this;
    self.getCfStoreData(getDate('-', '', false), 'week');
    self.ykfStatisticData(getDate('-', '', false), 'week');
    this.weekDate = getDate('-', '', false);
  },
  methods: {
    // 药康夫统计
    ykfStatisticData(date, type) {
      console.log('agentId', this.userInfo)
      let params = {}
      if (type === 'week') {
        params = {
          beginDate: getWeekStartAndEnd(0, date)[0],
          endDate: getWeekStartAndEnd(0, date)[1]
        }
      } else {
        params = {
          beginDate: getMonthStartAndEnd(0, date)[0],
          endDate: getMonthStartAndEnd(0, date)[1]
        }
      }
      params.agentId = this.userInfo.user.agentId;
      params.timePeriod = type;
      this.$http('get', '/yaokangfu/system/enterpriseStatistic/getHistory', params).then(res => {
        if (res.code == 200) {
          this.ykfData = res.data;
        }
      })
    },
    // 选择周
    chooseWeek() {
      if (this.dateType == 'week') {
        this.weelyShow = true;
        return;
      }
      this.dateType = 'week';
      if (this.week == '本周') {
        this.getCfStoreData(getDate('-', '', false), 'week');
        this.ykfStatisticData('', 'week');
      } else {
        this.getCfStoreData(this.weekDate, 'week');
        this.ykfStatisticData(this.weekDate, 'week');
      }
    },
    // 选择月
    chooseMonth() {
      if (this.dateType == 'month') {
        this.monthShow = true;
        return;
      }
      this.dateType = 'month';
      if (this.month == '本月') {
        this.getCfStoreData(getDate('month', '', false));
        this.ykfStatisticData(getDate('-', '', false), 'month');
      } else {
        this.getCfStoreData(this.month)
        this.ykfStatisticData(this.month, 'month');
      }
    },
    chooseType(type) {
      this.type = type;
    },
    //格式化月日期
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    weelyClose() {
      this.weelyShow = false;
      this.monthShow = false;
    },
    // onConfirm
    // 周
    onConfirmWeek(val) {
      console.log(val);
      this.week = val[1].text;
      this.weekDate = val[1].value;
      this.getCfStoreData(val[1].value, 'week');
      this.ykfStatisticData(val[1].value, 'week');
      this.weelyShow = false;
    },
    onCancelWeek() {
      this.weelyShow = false;
    },
    /**月
     * onConfirmMonth
     * onCancelMonth
     */
    onConfirmMonth(val) {
      console.log(val);
      this.month = getDate('month', val, false);
      this.getCfStoreData(this.month, 'month');
      this.ykfStatisticData(this.month, 'month');
      this.monthShow = false;
    },
    onCancelMonth() {
      this.monthShow = false;
    },
    // 在线处方系统接口
    getCfStoreData(date, type) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let params = {}
      if (type == 'week') {
        params = {
          beginTime: getWeekStartAndEnd(0, date)[0],
          endTime: getWeekStartAndEnd(0, date)[1],
        }
      } else {
        params = {
          month: date
        }
      }
      params.agentId = userInfo.user.agentId;
      // params.agentId = 1;
      this.$http('get', '/prescription/statistics/getH5AgentStatistic', params).then(res => {
        console.log('处方', res);
        if (res.code == 200) {
          this.prescriptionData = res.data;
          if (!this.prescriptionData.monthEnterpriseCountPrecent) {
            this.prescriptionData.monthEnterpriseCountPrecent = '--';
          } else {
            this.prescriptionData.monthEnterpriseCountPrecent = this.prescriptionData.monthEnterpriseCountPrecent + '%';
          }
          if (!this.prescriptionData.monthStoreCountPrecent) {
            this.prescriptionData.monthStoreCountPrecent = '--';
          } else {
            this.prescriptionData.monthStoreCountPrecent = this.prescriptionData.monthStoreCountPrecent + '%';
          }

        }
      })
    },
    // 电子处方药企、药店详情
    toCfDetail(type) {
      if (type == 1) { //药企
        console.log(this.prescriptionData.monthEnterpriseCountPrecent)
        let time = this.dateType == 'week' ? this.week : this.month;
        let type = this.dateType == 'week' ? 1 : 2 //1 周 2 月
        this.$router.push({ path: '/enterprises_data', query: { title: '在线处方', time: time, type: type, weekDate: this.weekDate, enterpriseNum: this.prescriptionData.monthEnterpriseCount, percent: this.prescriptionData.monthEnterpriseCountPrecent } })
      } else { //药店
        let time = this.dateType == 'week' ? this.week : this.month;
        let type = this.dateType == 'week' ? 1 : 2 //1 周 2 月
        this.$router.push({ path: '/store_data', query: { title: '在线处方', time: time, type: type, weekDate: this.weekDate, enterpriseNum: this.prescriptionData.monthStoreCount, percent: this.prescriptionData.monthStoreCountPrecent } })
      }
    },
    //药康夫药企、药店详情
    toYkfDetail(type) {
      if (type == 1) { //药企
        console.log(this.prescriptionData.monthEnterpriseCountPrecent)
        let time = this.dateType == 'week' ? this.week : this.month;
        let type = this.dateType == 'week' ? 1 : 2 //1 周 2 月
        this.$router.push({ path: '/enterprises_data_ykf', query: { title: '药康夫', time: time, type: type, weekDate: this.weekDate, enterpriseNum: this.ykfData.enterpriseAddCount, percent: this.ykfData.enterpriseRatio } })
      } else { //药店
        let time = this.dateType == 'week' ? this.week : this.month;
        let type = this.dateType == 'week' ? 1 : 2 //1 周 2 月
        this.$router.push({ path: '/store_data_ykf', query: { title: '药康夫', time: time, type: type, weekDate: this.weekDate, enterpriseNum: this.ykfData.storeAddCount, percent: this.ykfData.storeRatio } })
      }
    },
    otoData() {
      Toast('oto商城系统正在接入中，请耐心等待');
    }
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.home {
  padding-top: 0.65rem;
  min-height: calc(100vh - 1.63rem);
  background: #fff;
}
.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 0.5rem;
  height: 0.6rem;
  font-size: 0.28rem;
  color: #333;
  border-bottom: 1px solid #f7f6f8;
  background: #fff;
  padding-bottom: 0.05rem;
  .item {
    position: relative;
    padding: 0.1rem 0;
    margin-right: 0.88rem;
  }
  .active {
    color: #3780ff;
    font-size: 0.34rem;
  }
  .active::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -0.35rem;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    width: 0.2rem;
    height: 0.1rem;
    background: url("../assets/img/btn_dropdown@2x.png") no-repeat center / 100%
      100%;
  }
  .active::before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    width: 0.64rem;
    height: 3px;
    background: #3780ff;
    border-radius: 4px;
  }
}
.title {
  padding: 0.2rem 0.28rem;
  font-size: 0.3rem;
  font-weight: 500;
  color: #333333;
}
.card_box {
  margin: 0 0.3rem 0.4rem;
  .item {
    flex: 1;
    -webkit-flex: 1;
    text-align: center;
    box-shadow: 0 0 15px #f5f7fb;
    border-radius: 8px;
    background: #fff;
    .t1 {
      padding: 0.4rem 0 0.27rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      color: #666666;
    }
    .n {
      padding-bottom: 0.23rem;
      font-size: 0.6rem;
      line-height: 0.6rem;
      font-weight: bold;
      color: #3780ff;
    }
    .t2 {
      padding-bottom: 0.4rem;
      font-size: 0.26rem;
      line-height: 0.26rem;
      color: #333333;
    }
    .icon {
      position: relative;
    }
    .icon_up::after {
      content: "";
      display: inline-block;
      position: absolute;
      margin-left: 0.12rem;
      width: 0.16rem;
      height: 0.23rem;
      background: url("../assets/img/icon_rise_nor@2x.png") no-repeat center /
        100% 100%;
    }
    .icon_down::after {
      content: "";
      display: inline-block;
      position: absolute;
      margin-left: 0.12rem;
      width: 0.16rem;
      height: 0.23rem;
      background: url("../assets/img/icon_reduce_nor@2x.png") no-repeat center /
        100% 100%;
    }
  }
  .item:last-child {
    margin-left: 0.26rem;
    .n {
      color: #00c7c0;
    }
  }
}
</style>
