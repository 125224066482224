<template>
  <div class="mine">
    <div class="info">
      <div class="avatar">
        <img src="../assets/img/avatar.png">
      </div>
      <div class="list">
        <div class="item" @click="toPersonInfo">
          <div class="icon person_icon"></div>
          <div class="text">个人信息</div>
          <div class="icon2"></div>
        </div>
        <div class="item" @click="toChangePassword">
          <div class="icon psw_icon"></div>
          <div class="text">密码设置</div>
          <div class="icon2"></div>
        </div>
      </div>
      <div class="btn" @click="logout">退 出</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    toPersonInfo() {
      this.$router.push('/person_info');
    },
    toChangePassword() {
      this.$router.push('/change_psw')
    },
    logout() {
      this.$router.push('/login');
      localStorage.removeItem('userInfo');
    }
  }
}
</script>

<style lang="less" scoped>
.mine {
  min-height: calc(100vh - 0.98rem);
  padding-top: 1.3rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #fff url("../assets/img/my_bg@2x.png") no-repeat top / 100%
    2.36rem;
  .info {
    padding: 1.76rem 0 0;
    border-radius: 50px;
    background: #fff;
    .avatar {
      // margin: 0 auto 0;
      position: absolute;
      top: 0.5rem;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1.46rem;
      height: 1.46rem;
      border-radius: 50%;
      // border: 4px solid #FFFFFF;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .list {
    margin: 0 0.32rem;
    padding: 0 0.45rem;
    box-shadow: 0 0 0.2rem 4px #f5f7fb;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .item {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      padding: 0.42rem 0;
      border-bottom: 1px solid #eeeeee;
      .icon {
        margin-right: 0.33rem;
        width: 0.3rem;
        height: 0.3rem;
      }
      .person_icon {
        background: url("../assets/img/person_icon.png") no-repeat center / 100%
          100%;
      }
      .psw_icon {
        background: url("../assets/img/psw.png") no-repeat center / 100% 100%;
      }
      .text {
        flex: 1;
        -webkit-flex: 1;
        font-size: 0.3rem;
        color: #333333;
      }
      .icon2 {
        width: 0.13rem;
        height: 0.24rem;
        background: url("../assets/img/right_icon.png") no-repeat center / 100%
          100%;
      }
    }
    .item:last-child {
      border-bottom: none;
    }
  }
  .btn {
    margin: 0.78rem 0.4rem;
    padding: 0.28rem 0;
    font-size: 0.36rem;
    line-height: 0.36rem;
    color: #fff;
    background: #3780ff;
    border-radius: 8px;
    text-align: center;
  }
}
</style>
